export default function IconCheck() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="-0.75 -0.75 16 16"
      id="Validation-Check--Streamline-Micro"
      height="100%"
      width="100%"
    >
      <desc>{'Validation Check Streamline Icon: https://streamlinehq.com'}</desc>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.7250000000000001 9.062500000000002 3.2552500000000006 12.180000000000001a1.40795 1.40795 0 0 0 2.1750000000000003 -0.01305L13.775000000000002 1.8125000000000002"
        strokeWidth={1.5}
      />
    </svg>
  );
}
